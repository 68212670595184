import React, { Component } from "react";
import Slider from "react-slick";
import Sectiontitle from "../component/Banner/Sectiontitle";

class Clientslogo extends Component {
  constructor(props) {
    super(props);
    this.play = this.play.bind(this);
    this.pause = this.pause.bind(this);
  }
  play() {
    this.slider.slickPlay();
  }
  pause() {
    this.slider.slickPause();
  }
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      arrows: false,
      slidesToShow: 6,
      slidesToScroll: 2,
      autoplay: true,
      autoplaySpeed: 2000,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 3,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 2,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
      ],
    };
    return (
      <section className="clients_logo_area">
        <div className="container">
          <Sectiontitle
            Title="Carrier Partnerships"
            TitleP="We currently have contracted carrier partnerships established with over 50 logistics providers, and the list is growing every day!"
          />
          <Slider
            {...settings}
            ref={(slider) => (this.slider = slider)}
            className="clients_slider"
          >
            <div className="item">
              <a
                href="https://ascentgl.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={require("../image/ascent-logo.png")}
                  alt="Ascent Global Logistics Logo"
                />
              </a>
            </div>
            <div className="item">
              <a
                href="https://www.chrobinson.com/en-us/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={require("../image/ch-robinson-logo.png")}
                  alt="CH Robinson logo"
                />
              </a>
            </div>
            <div className="item">
              <a
                href="https://www.ntgfreight.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={require("../image/nolan-logo.png")} alt="NTG logo" />
              </a>
            </div>
            <div className="item">
              <a
                href="https://www.tql.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={require("../image/tql-logo.png")} alt="TQL logo" />
              </a>
            </div>
            <div className="item">
              <a href="https://www.mandplogistics.com/">
                <img
                  src={require("../image/m-and-p-logistics-logo.png")}
                  alt="M and P Logistics logo"
                />
              </a>
            </div>
            <div className="item">
              <a
                href="https://www.landstar.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={require("../image/landstar-logo.png")}
                  alt="Landstar logo"
                />
              </a>
            </div>
            <div className="item">
              <a
                href="https://www.fwf.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={require("../image/fwf-logo.png")} alt="FWF Logo" />
              </a>
            </div>
            <div className="item">
              <a
                href="https://www.tfwwi.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={require("../image/tfww-logo.png")} alt="TFWW Logo" />
              </a>
            </div>
            <div className="item">
              <a
                href="https://www.plslogistics.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={require("../image/pls-logo.png")} alt="PLS Logo" />
              </a>
            </div>
            <div className="item">
              <a
                href="https://lj-rogers.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={require("../image/lj-rogers-logo.png")}
                  alt="LJ Rogers Trucking logo"
                />
              </a>
            </div>
            <div className="item">
              <a
                href="https://www.trinitylogistics.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={require("../image/trinity-logo.png")}
                  alt="Trinity Logo"
                />
              </a>
            </div>
            <div className="item">
              <a
                href="https://www.jcrane.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={require("../image/jcrane-logo.png")}
                  alt="JCrane Logistics logo"
                />
              </a>
            </div>
            <div className="item">
              <a
                href="https://www.spicelogisticsllc.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={require("../image/spice-logo.png")}
                  alt="Spice Logistics logo"
                />
              </a>
            </div>
            <div className="item">
              <a
                href="https://www.genxfreight.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={require("../image/genx-logo.png")} alt="Gen X logo" />
              </a>
            </div>
            <div className="item">
              <a
                href="https://www.adicatrucking.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={require("../image/adica-logo.png")}
                  alt="Adica Logistics logo"
                />
              </a>
            </div>
          </Slider>
        </div>
      </section>
    );
  }
}
export default Clientslogo;
