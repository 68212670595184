import styled from "styled-components";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

const CustomModal = (props) => {
  const reset = (e) => {
    props.handleClick(e);
  };

  return (
    <React.Fragment>
      {props.showCustom === "open" && (
        <Container>
          <Content>
            <Header>
              <h2 className="mb_0 title_h2 t_color">{props.title}</h2>
              <button className="theme_btn" onClick={(event) => reset(event)}>
                X{/* <img src={require("../image/close-icon.svg")} alt="" /> */}
              </button>
            </Header>
            <MainContent>
              <div className="load_item wow fadeInUp" data-wow-delay="0.1s">
                <div className="flex_container">
                  {props.message}
                  {props.title === "View Rate Con" && <div></div>}
                </div>
              </div>
            </MainContent>
          </Content>
        </Container>
      )}
    </React.Fragment>
  );
};

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  color: black;
  background-color: rgba(0, 0, 0, 0.8);
  animation: fadeIn 0.4s;
`;

const Content = styled.div`
  width: 100%;
  max-width: 552px;
  background-color: white;
  max-height: 90%;
  overflow: initial;
  border-radius: 5px;
  position: relative;
  display: flex;
  flex-direction: column;
  top: 32px;
  margin: 0 auto;
`;

const Header = styled.div`
  padding: 16px 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  font-size: 16px;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.6);
  font-weight: 400;
  display: flex;
  justify-content: space-between;
  align-items: center;
  h2 {
    margin-bottom: 0;
  }
  button {
    display: flex;
    flex-direction: column;
    height: 40px;
    width: 40px;
    min-width: auto;
    /* color: rgba(0, 0, 0, 0.15); */
    align-items: center;
    margin: 0;
    padding: 5px;
  }
`;

const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: auto;
  vertical-align: baseline;
  background: transparent;
  padding: 8px 12px;
`;

const mapStateToProps = (state) => {
  return {
    user: state.userState.user,
    articles: state.articleState.articles,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(CustomModal);
