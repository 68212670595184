import styled from "styled-components";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import firebase from "firebase";
import { postArticleAPI } from "../actions";

const CreateLoadModal = (props) => {
  const [pick, setPick] = useState("");
  // const initPicks = [];
  // const initPickTimesStart = [];
  // const initPickTimesEnd = [];
  // const initDrops = [];
  // const initDropTimesStart = [];
  // const initDropTimesEnd = [];

  const [pickNumber, setPickNumber] = useState("1");
  const [dropNumber, setDropNumber] = useState("1");

  // const [picks, setPicks] = useState(initPicks);
  // const [pickTimesStart, setPickTimesStart] = useState(initPickTimesStart);
  // const [pickTimesEnd, setPickTimesEnd] = useState(initPickTimesEnd);

  // const [drops, setDrops] = useState(initDrops);
  // const [dropTimesStart, setDropTimesStart] = useState(initDropTimesStart);
  // const [dropTimesEnd, setDropTimesEnd] = useState(initDropTimesEnd);

  const [pickDate, setPickDate] = useState("");

  const [drop, setDrop] = useState("");
  const [dropDate, setDropDate] = useState("");

  const [miles, setMiles] = useState("");
  const [rate, setRate] = useState("");

  const [weight, setWeight] = useState("");
  const [length, setLength] = useState("");

  const [commodity, setCommodity] = useState("");
  const [notes, setNotes] = useState("");

  const [brokerage, setBrokerage] = useState("");
  const [pocName, setPocName] = useState("");
  const [pocPhone, setPocPhone] = useState("");

  const [rateCon, setRateCon] = useState("");
  const [pod, setPod] = useState("");

  const [assetArea, setAssetArea] = useState("");

  const [loadStatus, setLoadStatus] = useState("Active");
  const [loadType, setLoadType] = useState("Full");

  const [pickTimeStart, setPickTimeStart] = useState("08:00");
  const [pickTimeEnd, setPickTimeEnd] = useState("15:00");

  const [dropTimeStart, setDropTimeStart] = useState("08:00");
  const [dropTimeEnd, setDropTimeEnd] = useState("15:00");

  // useEffect(() => {
  //   picks.push("");
  // }, []);

  const handleChange = (e) => {
    const image = e.target.files[0];

    if (image === "" || image === undefined) {
      alert(`not an image, the file is a ${typeof image}`);
      return;
    }
    setRateCon(image);
  };

  const updatePod = (e) => {
    const imagePOD = e.target.files[0];
    console.log("image pod:", imagePOD);

    if (imagePOD === "" || imagePOD === undefined) {
      alert(`not an image, the file is a ${typeof imagePOD}`);
      return;
    }
    setPod(imagePOD);
  };

  const switchAssetArea = (area) => {
    setAssetArea(area);
  };

  const postArticle = (e) => {
    e.preventDefault();
    if (e.target !== e.currentTarget) {
      return;
    }

    if (pod === undefined) {
      setPod("");
    }

    if (rateCon === undefined) {
      setRateCon("");
    }

    const payload = {
      pickNumber: pickNumber,
      dropNumber: dropNumber,
      pick: pick,
      pickDate: pickDate,
      pickTimeStart: pickTimeStart,
      pickTimeEnd: pickTimeEnd,
      drop: drop,
      dropDate: dropDate,
      dropTimeStart: dropTimeStart,
      dropTimeEnd: dropTimeEnd,
      miles: miles,
      rate: rate,
      brokerage: brokerage,
      commodity: commodity,
      notes: notes,
      pocName: pocName,
      pocPhone: pocPhone,
      weight: weight,
      length: length,
      rateCon: rateCon,
      pod: pod,
      user: props.user,
      loadType: loadType,
      loadStatus: loadStatus,
      timestamp: firebase.firestore.Timestamp.now(),
    };
    props.postArticle(payload);
    reset(e);
  };

  const reset = (e) => {
    // setEditorText("");
    setRateCon("");
    setPod("");
    setAssetArea("");
    setPick("");
    setPickDate("");
    setDrop("");
    setDropDate("");
    setRate("");
    setMiles("");
    setBrokerage("");
    setCommodity("");
    setPocName("");
    setPocPhone("");
    setWeight("");
    setLength("");
    setLoadStatus("Active");
    setLoadType("Full");
    setPickTimeStart("08:00");
    setPickTimeEnd("15:00");
    setDropTimeStart("08:00");
    setDropTimeEnd("15:00");
    setPickNumber("1");
    setDropNumber("1");
    setNotes("");

    props.handleClick(e);
  };
  return (
    <React.Fragment>
      {props.showModal === "open" && (
        <Container>
          <Content>
            <Header>
              <h2 className="mb_0 title_h2 t_color">Create Load</h2>
              <button className="theme_btn" onClick={(event) => reset(event)}>
                X{/* <img src={require("../image/close-icon.svg")} alt="" /> */}
              </button>
            </Header>
            <MainContent>
              <div className="load_item wow fadeInUp" data-wow-delay="0.1s">
                <div className="flex_container">
                  <div className="flex_load_item">
                    Status
                    <select
                      value={loadStatus}
                      onChange={(e) => setLoadStatus(e.target.value)}
                      name="status"
                      className="form-control form-control-sm"
                    >
                      {loadStatus === "Active" ? (
                        <option selected>Active</option>
                      ) : (
                        <option>Active</option>
                      )}
                      {loadStatus === "Delivered" ? (
                        <option selected>Delivered</option>
                      ) : (
                        <option>Delivered</option>
                      )}
                      {loadStatus === "Factored" ? (
                        <option selected>Factored</option>
                      ) : (
                        <option>Factored</option>
                      )}
                    </select>
                  </div>

                  {/* <div className="flex_load_item"> */}
                  <FirstPicksWrapper>
                    Type
                    <select
                      onChange={(e) => setLoadType(e.target.value)}
                      value={loadType}
                      name="load-type"
                      className="form-control form-control-sm"
                    >
                      {loadType === "Full" ? (
                        <option selected>Full</option>
                      ) : (
                        <option>Full</option>
                      )}
                      {loadType === "Partial" ? (
                        <option selected>Partial</option>
                      ) : (
                        <option>Partial</option>
                      )}
                      {loadType === "TONU" ? (
                        <option selected>TONU</option>
                      ) : (
                        <option>TONU</option>
                      )}
                    </select>
                  </FirstPicksWrapper>
                  <PicksWrapper>
                    Picks
                    <input
                      name="pickNumber"
                      value={pickNumber}
                      type="text"
                      className="form-control"
                      aria-label="origin"
                      aria-describedby="inputGroup-sizing-default"
                      onChange={(e) => setPickNumber(e.target.value)}
                      required
                    />
                  </PicksWrapper>
                  <PicksWrapper>
                    Drops
                    <input
                      name="dropNumber"
                      value={dropNumber}
                      type="text"
                      className="form-control"
                      aria-label="origin"
                      aria-describedby="inputGroup-sizing-default"
                      onChange={(e) => setDropNumber(e.target.value)}
                      required
                    />
                  </PicksWrapper>
                  {/* </div> */}
                </div>

                <div className="flex_container">
                  <div className="flex_load_info">
                    Origin
                    <input
                      name="pick"
                      value={pick}
                      type="text"
                      className="form-control"
                      aria-label="origin"
                      aria-describedby="inputGroup-sizing-default"
                      onChange={(e) => setPick(e.target.value)}
                      required
                    />
                    <input
                      type="date"
                      name="pickdate"
                      value={pickDate}
                      className="form-control"
                      aria-label="Origin Date"
                      aria-describedby="inputGroup-sizing-default"
                      onChange={(e) => setPickDate(e.target.value)}
                      required
                    />
                    {/* pick
                    <input
                      name="pick"
                      value={pick}
                      type="text"
                      className="form-control"
                      aria-label="origin"
                      aria-describedby="inputGroup-sizing-default"
                      onChange={(e) => setPick(e.target.value)}
                      required
                    />
                    <input
                      type="date"
                      name="pickdate"
                      value={pickDate}
                      className="form-control"
                      aria-label="Origin Date"
                      aria-describedby="inputGroup-sizing-default"
                      onChange={(e) => setPickDate(e.target.value)}
                      required
                    /> */}
                    <div className="flex_container">
                      <TimeWrapper className="inputGroup-sizing-sm">
                        <input
                          type="time"
                          name="pickTimeStart"
                          value={pickTimeStart}
                          className="form-control"
                          aria-label="Pick Time Start"
                          onChange={(e) => setPickTimeStart(e.target.value)}
                          required
                        />
                      </TimeWrapper>
                      <TimeWrapper className="inputGroup-sizing-sm">
                        <input
                          type="time"
                          name="pickDateEnd"
                          value={pickTimeEnd}
                          className="form-control right-align"
                          aria-label="Pick Date"
                          onChange={(e) => setPickTimeEnd(e.target.value)}
                          required
                        />
                      </TimeWrapper>
                    </div>
                  </div>

                  <div className="flex_load_info">
                    Destination
                    <input
                      name="drop"
                      value={drop}
                      type="text"
                      className="form-control right-align"
                      aria-label="pick date"
                      onChange={(e) => setDrop(e.target.value)}
                      required
                    />
                    <input
                      type="date"
                      name="dropdate"
                      value={dropDate}
                      className="form-control right-align"
                      aria-label="Drop Date"
                      onChange={(e) => setDropDate(e.target.value)}
                      required
                    />
                    <div className="flex_container">
                      <TimeWrapper className="inputGroup-sizing-sm">
                        <input
                          type="time"
                          name="dropTimeStart"
                          value={dropTimeStart}
                          className="form-control"
                          aria-label="Drop Time Start"
                          onChange={(e) => setDropTimeStart(e.target.value)}
                          required
                        />
                      </TimeWrapper>
                      <TimeWrapper className="inputGroup-sizing-sm">
                        <input
                          type="time"
                          name="dropDateEnd"
                          value={dropTimeEnd}
                          className="form-control right-align"
                          aria-label="Drop Date"
                          onChange={(e) => setDropTimeEnd(e.target.value)}
                          required
                        />
                      </TimeWrapper>
                    </div>
                  </div>
                </div>

                <div className="flex_container top_border">
                  <div className="flex_load_item">
                    Rate
                    <input
                      name="rate"
                      value={rate}
                      onChange={(e) => setRate(e.target.value)}
                      type="text"
                      className="form-control"
                      aria-label="destination"
                      aria-describedby="inputGroup-sizing-default"
                      required
                    />
                  </div>
                  <div className="flex_load_item">
                    Miles
                    <input
                      name="miles"
                      value={miles}
                      onChange={(e) => setMiles(e.target.value)}
                      type="text"
                      className="form-control"
                      aria-label="miles"
                      aria-describedby="inputGroup-sizing-default"
                      required
                    />
                  </div>
                </div>
                <div className="flex_container">
                  <div className="flex_load_item">
                    Weight
                    <input
                      name="weight"
                      value={weight}
                      onChange={(e) => setWeight(e.target.value)}
                      type="text"
                      className="form-control"
                      aria-label="weight"
                      aria-describedby="inputGroup-sizing-default"
                      required
                    />
                  </div>
                  <div className="flex_load_item">
                    Length
                    <input
                      name="length"
                      value={length}
                      onChange={(e) => setLength(e.target.value)}
                      type="text"
                      className="form-control"
                      aria-label="length"
                      aria-describedby="inputGroup-sizing-default"
                      required
                    />
                  </div>
                  <div className="flex_load_item">
                    Commodity
                    <input
                      name="commodity"
                      value={commodity}
                      onChange={(e) => setCommodity(e.target.value)}
                      type="text"
                      className="form-control"
                      aria-label="commodity"
                      aria-describedby="inputGroup-sizing-default"
                      required
                    />
                  </div>
                  <div className="flex_load_item">
                    Notes
                    <input
                      name="notes"
                      value={notes}
                      onChange={(e) => setNotes(e.target.value)}
                      type="text"
                      className="form-control"
                      aria-label="notes"
                      required
                    />
                  </div>
                </div>

                <div className="flex_container top_border t_color">
                  <div className="flex_load_full">
                    <h2>Brokerage</h2>
                    <input
                      name="brokerage"
                      value={brokerage}
                      onChange={(e) => setBrokerage(e.target.value)}
                      type="text"
                      className="form-control"
                      aria-label="brokerage"
                      aria-describedby="inputGroup-sizing-default"
                      required
                    />
                  </div>
                  <div className="flex_load_item">
                    POC
                    <input
                      name="pocName"
                      value={pocName}
                      onChange={(e) => setPocName(e.target.value)}
                      type="text"
                      className="form-control"
                      aria-label="poc Name"
                      aria-describedby="inputGroup-sizing-default"
                      required
                    />
                  </div>
                  <div className="flex_load_item">
                    Phone
                    <input
                      name="pocPhone"
                      value={pocPhone}
                      onChange={(e) => setPocPhone(e.target.value)}
                      type="text"
                      className="form-control"
                      aria-label="pocPhone"
                      aria-describedby="inputGroup-sizing-default"
                      required
                    />
                  </div>
                </div>
              </div>

              <Editor>
                {assetArea === "rateCon" ? (
                  <UploadImage>
                    {/* Only display file input until file is selected */}
                    {!rateCon && !pod && (
                      <input
                        type="file"
                        accept=".gif, .jpeg, .png, .pdf"
                        name="image"
                        id="file"
                        // display none hides file upload button and dialog
                        // style={{ display: "none" }}
                        onChange={handleChange}
                      />
                    )}

                    {!rateCon ? (
                      <label htmlFor="pod">Select the file to attach</label>
                    ) : (
                      <label htmlFor="pod">Rate Confirmation attached.</label>
                    )}
                    {rateCon && (
                      <iframe
                        title="display-ratecon"
                        src={URL.createObjectURL(rateCon)}
                      />
                    )}
                  </UploadImage>
                ) : (
                  assetArea === "POD" && (
                    <UploadImage>
                      {(!rateCon || !pod) && (
                        <input
                          type="file"
                          accept=".gif, .jpeg, .png, .pdf"
                          name="pods"
                          id="pod"
                          // display none hides file upload button and dialog
                          // style={{ display: "none" }}
                          onChange={updatePod}
                        />
                      )}
                      <p>
                        {!pod ? (
                          <label htmlFor="pod">Select the file to attach</label>
                        ) : (
                          <label htmlFor="pod">POD attached</label>
                        )}
                      </p>
                      {pod && (
                        <iframe
                          title="display-pod"
                          src={URL.createObjectURL(pod)}
                        />
                      )}
                    </UploadImage>
                  )
                )}
              </Editor>
            </MainContent>
            <ShareCreation>
              <AttachAssets>
                {/* {rateCon && <div>RC: {rateCon.name}</div>}
                {pod && <div>POD: {pod.name}</div>} */}
                {!rateCon && (
                  <AssetButton
                    className="theme_btn assets"
                    // disabled={pod ? true : false}
                    onClick={() => switchAssetArea("rateCon")}
                  >
                    Add Rate Con
                  </AssetButton>
                )}
                {!pod && (
                  <AssetButton
                    className="theme_btn assets"
                    // disabled={rateCon ? true : false}
                    onClick={() => switchAssetArea("POD")}
                  >
                    Add POD
                  </AssetButton>
                )}
                {rateCon && (
                  <div className="file-details">RC: {rateCon.name}</div>
                )}
                {pod && <div className="file-details">POD: {pod.name}</div>}
              </AttachAssets>

              <AssetButton
                className="theme_btn"
                disabled={
                  !pick || !pickDate || !drop || !dropDate ? true : false
                }
                onClick={(event) => postArticle(event)}
              >
                Create Load
              </AssetButton>
            </ShareCreation>
          </Content>
        </Container>
      )}
    </React.Fragment>
  );
};

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  color: black;
  background-color: rgba(0, 0, 0, 0.8);
  animation: fadeIn 0.4s;
`;

const Content = styled.div`
  width: 100%;
  max-width: 552px;
  background-color: white;
  max-height: 90%;
  overflow: initial;
  border-radius: 5px;
  position: relative;
  display: flex;
  flex-direction: column;
  top: 32px;
  margin: 0 auto;
`;

const Header = styled.div`
  padding: 16px 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  font-size: 16px;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.6);
  font-weight: 400;
  display: flex;
  justify-content: space-between;
  align-items: center;
  h2 {
    margin-bottom: 0;
  }
  button {
    display: flex;
    flex-direction: column;
    height: 40px;
    width: 40px;
    min-width: auto;
    /* color: rgba(0, 0, 0, 0.15); */
    align-items: center;
    margin: 0;
    padding: 5px;
  }
`;

const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: auto;
  vertical-align: baseline;
  background: transparent;
  padding: 8px 12px;
`;

const Editor = styled.div`
  padding: 12px 24px;
  textarea {
    width: 100%;
    min-height: 100px;
    resize: none;
    padding: 5px;
  }

  input {
    width: 100%;
    height: 45px;
    font-size: 16px;
    margin-bottom: 20px;
    padding: 5px;
  }
`;

const ShareCreation = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 12px 24px 12px 16px;
`;

const AssetButton = styled.button`
  font-size: 14px;
  font-weight: 700;
  padding: 2px;
  margin-right: 2px;
  background: ${(props) => (props.disabled ? "rgba(0,0,0,0.5)" : "inherit")};
  color: ${(props) => (props.disabled ? "rgba(1,1,1,0.5)" : "inherit")};
  /* &:hover {
    background: ${(props) => (props.disabled ? "green" : "red")};
  } */
  @media (max-width: 768px) {
    font-size: 12px;
    max-width: 50px;
  }
`;

const AttachAssets = styled.div`
  align-items: center;
  display: flex;
  padding-right: 8px;
  ${AssetButton} {
    /* width: 100px; */
  }
`;

const UploadImage = styled.div`
  text-align: center;
  img {
    width: 100%;
  }
  iframe {
    width: 100%;
  }
`;

const FirstPicksWrapper = styled.div`
  flex: 1 0 30%;
  padding: 2px;
  font-size: 14px;
  font-weight: 800;
  /* font-size: 8px; */
  input {
    font-size: 11px;
  }
`;

const PicksWrapper = styled.div`
  flex: 1 0 10%;
  padding: 2px;
  font-size: 14px;
  font-weight: 800;
  /* font-size: 8px; */
  input {
    font-size: 11px;
  }
`;

const TimeWrapper = styled.div`
  width: 50%;
  font-size: 7px;
  input {
    font-size: 12px;
  }
`;

const mapStateToProps = (state) => {
  return {
    user: state.userState.user,
  };
};

const mapDispatchToProps = (dispatch) => ({
  postArticle: (payload) => dispatch(postArticleAPI(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateLoadModal);
