import React, { Component } from "react";
import { Link } from "react-router-dom";
import Fade from "react-reveal/Fade";

const Footer = (props) => {
  let jhonData = props.jhonData;
  return (
    <section className="footer-area">
      <div className="container">
        <div className="footer-content">
          <Link to="/" className="logo wow fadeInDown" data-wow-delay="0.4s">
            <img src={require("../image/tomcat-transport-logo.png")} alt="" />
          </Link>
          <Fade top cascade>
            <ul className="list_style">
              {jhonData.socialLinks.map((item) => {
                return (
                  <li key={item.name}>
                    <a href={item.url}>
                      <i className={item.className}></i>
                    </a>
                  </li>
                );
              })}
            </ul>
          </Fade>

          <h6>
            Site design by <a href="mailto:busfactorx@gmail.com">MBussen</a>
          </h6>
          <p>© 2021 Tomcat Transport LLC - All Rights Reserved</p>
        </div>
      </div>
    </section>
  );
};

export default Footer;
