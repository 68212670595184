import { combineReducers } from "redux";
import loadReducer from "./loadReducer";
import userReducer from "./userReducer";

const rootReducer = combineReducers({
  userState: userReducer,
  articleState: loadReducer,
});

export default rootReducer;
