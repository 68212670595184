const jhonData = {
  name: "Tomcat Transport",
  name2: "Richard Tea.",
  name3: "Alexa Johnson",
  role: "Trusted Logistics Partners",
  ptext: "Creative Designer & Developer located in London.",
  socialLinks: [
    {
      name: "Facebook",
      url: "http://www.facebook.com/",
      className: "social_facebook",
    },
    {
      name: "Linked In",
      url: "http://www.linkedin.com/",
      className: "social_linkedin",
    },
  ],
  aboutme: "About Us",
  aboutdetails:
    'Strategically located in the Midwest, we specialize in hauling time-sensitive goods, products, and equipment loads that weigh sixteen thousand pounds or less.  We provide regional service for Dayton, Cincinnati, Columbus, Cleveland, Louisville, St. Louis, Pittsburgh, Chicago, Detroit, Minneapolis, Indianapolis to Philadelphia and the east coast. We are experienced in this market niche and live and breath "just in time" delivery.',
  service: [
    {
      id: 1,
      iconName: "icon-linegraph",
      serviceTitle: "Hotshots",
      sDetails:
        "We offer dedicated service, which means no waiting for other freight to be picked up, no cross-dock or potential delays or damage from transfers. Perfect for time critical shipments.",
    },
    {
      id: 2,
      iconName: "icon-pencil",
      serviceTitle: "Value",
      sDetails:
        "Full-size trucks are more expensive to run and operate and charge accordingly. Our specialized fleet allows us to deliver great service at even better rates.",
    },
    {
      id: 3,
      iconName: "icon-presentation",
      serviceTitle: "Analytics",
      sDetails:
        "Our custom portfolio of web based management tools and resource rich analytics provides key business insights while enhancing efficiency and increasing profitability.",
    },
  ],
  contact: [
    {
      id: "1",
      title: "Contact Info",
      menuItems: [
        {
          id: 1,
          icon: "icon_pin_alt",
          text: "Address: 1464 Melrose Ave - Dayton, OH",
        },
        {
          id: 2,
          icon: "icon_phone",
          text: "Phone: 937-716-4896",
        },
        {
          id: 3,
          icon: "icon_mail",
          text: "Email: TomcatTransportLLC@gmail.com",
        },
        {
          id: 4,
          icon: "icon_globe",
          text: "Website: https://TomcatTransportLLC.com",
        },
      ],
    },
  ],
  devloperBanner: [
    {
      id: 1,
      subtile: "Front-End Developer",
      title: "Talk is cheap. \n Show me the code",
      discription:
        "I design and code beautifully simple things,\n and I love what I do.",
    },
  ],
  devAbout: [
    {
      id: 1,
      smalltitle: "Introduce",
      aHead: "Passion \n Heart & Soul",
      aHeadTwo: "Every great design begin with an even better story",
      adiscription:
        "Since beginning my journey as a freelancer designer nearly 4 years ago, I've done remote work for agencies, consulted for startups, and collaborated with talented people to create digital products for both business and consumer use, I am quietly confident, naturally curious.",
    },
  ],
  aboutSkill: [
    {
      id: 1,
      workName: "Design",
      text: "Create digital products with unique idea",
      complateProject: "20 Projects",
      icon: "icon-tools",
    },
    {
      id: 2,
      workName: "front-End",
      text: "My coding structure is clean and smooth",
      complateProject: "165 Projects",
      icon: "icon-genius",
    },
    {
      id: 3,
      workName: "SEO",
      text: "Boost your business with SEO optimize.",
      complateProject: "5 Projects",
      icon: "icon-linegraph",
    },
  ],
  awards: [
    {
      id: 1,
      image: "logo1.png",
      ctile: "Site of the year 2020",
      date: "April 2020",
      description: "For most favorited design voted by NY City Style customers",
      url: "http://www.pinterest.com",
      linktext: "view More",
    },
    {
      id: 2,
      image: "logo2.png",
      ctile: "Theme of the day 2021",
      date: "March 2021",
      description: "For most favorited design voted by NY City Style customers",
      url: "http://www.pinterest.com",
      linktext: "view More",
    },
    {
      id: 3,
      image: "logo2.png",
      ctile: "Best design awwards",
      date: "March 2021",
      description: "For most favorited design voted by NY City Style customers",
      url: "http://www.pinterest.com",
      linktext: "view More",
    },
  ],
  contacttitle: "Contact Form",
};

export default jhonData;
