import { auth, provider, storage } from "../firebase";
import db from "../firebase";
import {
  SET_USER,
  SET_LOADING_STATUS,
  GET_ARTICLES,
  GET_ARTICLES_BY,
} from "./actionType";

export const setUser = (payload) => ({
  type: SET_USER,
  user: payload,
});

export const setLoading = (status) => ({
  type: SET_LOADING_STATUS,
  status: status,
});

export const getArticles = (payload) => ({
  type: GET_ARTICLES,
  payload: payload,
});

export const getArticlesBy = (payload) => ({
  type: GET_ARTICLES_BY,
  payload: payload,
});

// export const setPodUrl = (payload) => ({
//   type: SET_POD_URL,
//   payload: payload,
// });

export function signInAPI() {
  return (dispatch) => {
    auth
      .signInWithPopup(provider)
      .then((payload) => {
        if (
          // payload.user.displayName === "Matthew Bussen"
          payload.user.displayName === "Matthew Bussen" ||
          payload.user.displayName === "Pete Bussen"
        ) {
          dispatch(setUser(payload.user));
        } else {
          alert("Your account is not approved for access at this time.");
        }
      })
      .catch((error) => alert(error.message));
  };
}

export function getUserAuth() {
  return (dispatch) => {
    auth.onAuthStateChanged(async (user) => {
      if (user) {
        dispatch(setUser(user));
      }
    });
  };
}

export function signOutAPI() {
  return (dispatch) => {
    auth
      .signOut()
      .then(() => {
        dispatch(setUser(null));
      })
      .catch((error) => {
        console.log(error.message);
      });
  };
}

export function deleteLoadAPI(lookupID) {
  return (dispatch) => {
    db.collection("articles").doc(lookupID).delete();
  };
}

export function removeRateConAPI(lookupID) {
  return (dispatch) => {
    db.collection("articles").doc(lookupID).update({
      rateConURL: "",
    });
  };
}

export function removePodAPI(lookupID) {
  return (dispatch) => {
    db.collection("articles").doc(lookupID).update({
      podURL: "",
    });
  };
}

export function updateLoadAPI(payload, lookupID) {
  return (dispatch) => {
    dispatch(setLoading(true));

    const execute = async () => {
      let downloadURL1 = "";
      let downloadURL2 = "";

      console.log("payload: ", payload);

      // logic to check if there is pre-existing url
      if (
        payload.rateCon !== "" &&
        payload.rateCon !== undefined &&
        payload.rateCon.slice(0, 4) !== "http"
      ) {
        const snapshot1 = await storage
          .ref(`documents/${payload.rateCon.name}`)
          .put(payload.rateCon);

        downloadURL1 = await snapshot1.ref.getDownloadURL();
      } else if (payload.rateCon.slice(0, 4) === "http") {
        downloadURL1 = payload.rateCon;
      }

      if (
        payload.pod !== "" &&
        payload.pod !== undefined &&
        payload.pod.slice(0, 4) !== "http"
      ) {
        const snapshot2 = await storage
          .ref(`documents/${payload.pod.name}`)
          .put(payload.pod);

        downloadURL2 = await snapshot2.ref.getDownloadURL();
      } else if (payload.pod.slice(0, 4) === "http") {
        downloadURL2 = payload.pod;
      }

      db.collection("articles")
        .doc(lookupID)
        .set({
          actor: {
            description: payload.user.email,
            title: payload.user.displayName,
            date: payload.timestamp,
            image: payload.user.photoURL,
          },
          pickNumber: payload.pickNumber,
          dropNumber: payload.dropNumber,
          loadStatus: payload.loadStatus,
          loadType: payload.loadType,
          pick: payload.pick,
          pickDate: payload.pickDate,
          pickTimeStart: payload.pickTimeStart,
          pickTimeEnd: payload.pickTimeEnd,
          drop: payload.drop,
          dropDate: payload.dropDate,
          dropTimeStart: payload.dropTimeStart,
          dropTimeEnd: payload.dropTimeEnd,
          miles: payload.miles,
          rate: payload.rate,
          brokerage: payload.brokerage,
          commodity: payload.commodity,
          notes: payload.notes,
          pocName: payload.pocName,
          pocPhone: payload.pocPhone,
          weight: payload.weight,
          length: payload.length,
          podURL: downloadURL2,
          rateConURL: downloadURL1,
        });
      dispatch(setLoading(false));
    };

    execute();
  };
}

export function postArticleAPI(payload) {
  return (dispatch) => {
    dispatch(setLoading(true));

    // let downloadURL1 = "";
    // let downloadURL2 = "";

    const execute = async () => {
      let downloadURL1 = "";
      let downloadURL2 = "";

      if (payload.rateCon !== "") {
        const snapshot1 = await storage
          .ref(`documents/${payload.rateCon.name}`)
          .put(payload.rateCon);

        downloadURL1 = await snapshot1.ref.getDownloadURL();
      }

      if (payload.pod !== "") {
        const snapshot2 = await storage
          .ref(`documents/${payload.pod.name}`)
          .put(payload.pod);

        downloadURL2 = await snapshot2.ref.getDownloadURL();
      }

      db.collection("articles").add({
        actor: {
          description: payload.user.email,
          title: payload.user.displayName,
          date: payload.timestamp,
          image: payload.user.photoURL,
        },
        pickNumber: payload.pickNumber,
        dropNumber: payload.dropNumber,
        pick: payload.pick,
        pickDate: payload.pickDate,
        pickTimeStart: payload.pickTimeStart,
        pickTimeEnd: payload.pickTimeEnd,
        drop: payload.drop,
        dropDate: payload.dropDate,
        dropTimeStart: payload.dropTimeStart,
        dropTimeEnd: payload.dropTimeEnd,
        miles: payload.miles,
        rate: payload.rate,
        brokerage: payload.brokerage,
        commodity: payload.commodity,
        notes: payload.notes,
        pocName: payload.pocName,
        pocPhone: payload.pocPhone,
        weight: payload.weight,
        length: payload.length,
        podURL: downloadURL2,
        rateConURL: downloadURL1,
        loadType: payload.loadType,
        loadStatus: payload.loadStatus,
      });
      dispatch(setLoading(false));
    };

    execute();
  };
}

export function getArticlesAPI() {
  return (dispatch) => {
    let payload;

    db.collection("articles")
      .orderBy("actor.date", "desc")
      .onSnapshot((snapshot) => {
        payload = snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
        dispatch(getArticles(payload));
      });
  };
}

export function getArticlesByAPI(search, where, limit) {
  return (dispatch) => {
    let searchInput = search.split(": ");

    if (where === "" && limit === "") {
      let payload;

      db.collection("articles")
        .orderBy(searchInput[0], searchInput[1])
        .onSnapshot((snapshot) => {
          payload = snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
          dispatch(getArticles(payload));
        });
    }

    if (where !== "" && limit === "") {
      let payload;
      let whereInput = where.split(": ");

      db.collection("articles")
        .orderBy(searchInput[0], searchInput[1])
        .where(whereInput[0], "==", whereInput[1])
        .onSnapshot((snapshot) => {
          payload = snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
          dispatch(getArticles(payload));
        });
    }

    if (where === "" && limit !== "") {
      let payload;

      db.collection("articles")
        .orderBy(searchInput[0], searchInput[1])
        .limit(limit)
        .onSnapshot((snapshot) => {
          payload = snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
          dispatch(getArticles(payload));
        });
    }

    if (where !== "" && limit !== "") {
      let payload;
      let whereInput = where.split(": ");

      db.collection("articles")
        .orderBy(searchInput[0], searchInput[1])
        .where(whereInput[0], "==", whereInput[1])
        .limit(limit)
        .onSnapshot((snapshot) => {
          payload = snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
          dispatch(getArticles(payload));
        });
    }

    // if (where !== 0 && limit === 0) {
    //   let payload;

    //   db.collection("articles")
    //     .orderBy(key, direction)
    //     .onSnapshot((snapshot) => {
    //       payload = snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
    //       dispatch(getArticles(payload));
    //     });
    // }
  };
}

export function testMsgAPI(msg) {
  return (dispatch) => {
    console.log("mmmmMMmmm", msg);
  };
}
