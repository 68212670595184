import React, { useEffect, useState } from "react";
import { getArticlesByAPI } from "./actions";
import LoadNav from "./component/Navbar/LoadNav";
import Footer from "./component/Footer";
import jhonData from "./component/jhonData";
import { connect } from "react-redux";
import CreateLoadModal from "./component/CreateLoadModal";
import EditLoadModal from "./component/EditLoadModal";
import styled from "styled-components";
import Fade from "react-reveal/Fade";
import DeleteLoadModal from "./component/DeleteLoadModal";
import Reveal from "react-reveal/Reveal/";
import CustomModal from "./component/CustomModal";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  BarChart,
  // Cell,
  // Line,
  Bar,
  Legend,
  // defs,
  // linearGradient,
} from "recharts";

// const CustomTooltip = ({ active, payload, label }) => {
//   if (active && payload && payload.length) {
//     return (
//       <div className="custom-tooltip">
//         <p className="label">{`${label} : ${payload[0].value}`}</p>
//         <p className="intro">{label}</p>
//         <p className="desc">My message</p>
//       </div>
//     );
//   }

//   return null;
// };

const LoadManager = (props) => {
  const [showModal, setShowModal] = useState("close");
  const [showEdit, setShowEdit] = useState("close");
  const [showDelete, setShowDelete] = useState("close");
  const [showCustom, setShowCustom] = useState("close");
  const [loadID, setLoadId] = useState("");
  const [loadKEY, setLoadKEY] = useState("");
  const [customeTitle, setCustomeTitle] = useState("");
  const [customeMessage, setCustomeMessage] = useState("");
  const [search, setSearch] = useState("pickDate: desc");
  const [where, setWhere] = useState("");
  const [limit, setLimit] = useState("9");

  const graphData = [];
  const allRPM = [];
  const allRates = [];
  const currLoadTotals = [];

  useEffect(() => {
    // comments out old hard coded get articles
    // props.getArticles();
    props.getArticlesBy(search, where, limit);
  }, []);

  const weeklyData = [
    { date: "7/19", weekly: 4150, monthly: 4259.5 },
    { date: "7/26", weekly: 4369, monthly: 4259.5 },
    { date: "8/9", weekly: 4850, monthly: 4000 },
    { date: "8/16", weekly: 3450, monthly: 4000 },
    { date: "8/30", weekly: 3700, monthly: 4000 },
    { date: "9/6", weekly: 4600, monthly: 4650 },
    { date: "9/20", weekly: 3900, monthly: 4650 },
    { date: "9/27", weekly: 5450, monthly: 4650 },
    { date: "10/4", weekly: 3850, monthly: 4050 },
    { date: "10/18", weekly: 4550, monthly: 4050 },
    { date: "10/25", weekly: 3750, monthly: 4050 },
    { date: "11/8", weekly: 4950, monthly: 4950 },
    { date: "12/6", weekly: 3200, monthly: 3725 },
    { date: "12/13", weekly: 4250, monthly: 3725 },
    { date: "1/3", weekly: 5500, monthly: 4650 },
    { date: "1/10", weekly: 3800, monthly: 4650 },
    { date: "2/14", weekly: 5750, monthly: 5200 },
    { date: "2/21", weekly: 4650, monthly: 5200 },
    { date: "3/7", weekly: 4650, monthly: 5150 },
    { date: "3/14", weekly: 4750, monthly: 5150 },
    { date: "3/21", weekly: 6050, monthly: 5150 },
    { date: "4/4", weekly: 5891, monthly: 5322 },
    { date: "4/18", weekly: 5850, monthly: 5322 },
    { date: "4/25", weekly: 4225, monthly: 5322 },
    { date: "5/9", weekly: 4020, monthly: 4231.67 },
    { date: "5/16", weekly: 4300, monthly: 4231.67 },
    { date: "5/30", weekly: 4375, monthly: 4231.67 },
  ];

  const formatDate = (date) => {
    let newDate = date.split("-");
    newDate.push(newDate.shift());
    newDate = newDate.join("/");

    return newDate;
  };

  const displayRPM = (rate, miles) => {
    // formats rate per miles for display output
    // limits to 2 decimal places
    let rpmOutput = (parseInt(rate) / parseInt(miles)).toFixed(2);
    return rpmOutput;
  };

  // function to generate arrays for dashboard data
  const genData = (loads) => {
    loads.map((load) => {
      // let output;
      let rpm = displayRPM(load.rate, load.miles);

      let output = {
        name: formatDate(load.pickDate).slice(0, 5),
        rate: parseInt(load.rate),
        rpm: rpm,
      };

      switch (rpm) {
        case "NaN":
          break;
        default:
          allRPM.push(parseFloat(rpm));
          allRates.push(parseInt(load.rate));
          if (load.loadStatus !== "Factored") {
            currLoadTotals.push(parseFloat(load.rate));
            console.log(currLoadTotals);
          }
          graphData.push(output);
          break;
      }

      if (
        (load.loadType === "TONU" && load.loadStatus !== "Factored") ||
        (load.loadType === "Partial" && load.loadStatus !== "Factored")
      ) {
        currLoadTotals.push(parseFloat(load.rate));
      }

      return graphData;
    });
  };

  const calcAvgLoad = () => {
    let sum = allRates.reduce((a, b) => a + b, 0);
    let avg = (sum / allRates.length).toFixed(2);
    return avg;
  };

  const calcAvgRPM = () => {
    let sum = allRPM.reduce((a, b) => a + b, 0);
    let avg = (sum / allRPM.length).toFixed(2);
    console.log({ allRPM }, sum, avg);
    return avg;
  };

  const calcWeeklyAvg = () => {
    let Weeklytotals = [];
    weeklyData.map((week) => Weeklytotals.push(parseFloat(week.weekly)));
    let sum = Weeklytotals.reduce((a, b) => a + b, 0);
    let avg = (sum / Weeklytotals.length).toFixed(2);
    return avg;
  };

  const displayCurrentWeek = () => {
    let sum = currLoadTotals.reduce((a, b) => a + b, 0);
    return sum;
  };

  const runSearch = (e) => {
    e.preventDefault();
    if (e.target !== e.currentTarget) {
      return;
    }

    props.getArticlesBy(search, where, limit);
  };

  const handleClick = (e) => {
    e.preventDefault();
    if (e.target !== e.currentTarget) {
      return;
    }

    switch (showModal) {
      case "open":
        setShowModal("close");
        break;
      case "close":
        setShowModal("open");
        break;
      default:
        setShowModal("close");
        break;
    }
  };

  const handleEdit = (e, id = loadID, key = loadKEY) => {
    e.preventDefault();
    if (e.target !== e.currentTarget) {
      return;
    }
    setLoadId(id);
    setLoadKEY(key);
    switch (showEdit) {
      case "open":
        setShowEdit("close");
        break;
      case "close":
        setShowEdit("open");
        break;
      default:
        setShowEdit("close");
        break;
    }
  };

  const handleDelete = (e, id = loadID, key = loadKEY) => {
    e.preventDefault();
    if (e.target !== e.currentTarget) {
      return;
    }
    setLoadId(id);
    setLoadKEY(key);
    switch (showDelete) {
      case "open":
        setShowDelete("close");
        break;
      case "close":
        setShowDelete("open");
        break;
      default:
        setShowDelete("close");
        break;
    }
  };

  const handleCustom = (e) => {
    e.preventDefault();
    if (e.target !== e.currentTarget) {
      return;
    }

    switch (showCustom) {
      case "open":
        setShowCustom("close");
        break;
      case "close":
        setShowCustom("open");
        break;
      default:
        setShowCustom("close");
        break;
    }
  };

  return (
    <div className="body_wrapper">
      <LoadNav
        mClass="dark_menu"
        mContainer="custome_container"
        mainlogo="tomcat-transport-logo-sticky.png"
        stickylogo="tomcat-transport-logo-sticky.png"
      />
      <CreateLoadModal showModal={showModal} handleClick={handleClick} />
      <EditLoadModal
        loadID={loadID}
        loadKEY={loadKEY}
        showEdit={showEdit}
        handleClick={handleEdit}
      />
      <DeleteLoadModal
        loadID={loadID}
        loadKEY={loadKEY}
        showDelete={showDelete}
        handleClick={handleDelete}
      />
      <CustomModal
        title={customeTitle}
        message={customeMessage}
        showCustom={showCustom}
        handleClick={handleCustom}
      />

      <section className="work_area" id="dashboard">
        <div className="container">
          <div className="section_title text-center mb_60">
            {/* <Reveal effect="fadeInUp">
              <h2 className="mb_60 title_h2 t_color">Dashboard</h2>
            </Reveal> */}

            {genData(props.articles)}

            <Fade bottom cascade duration={1000}>
              <div className="row">
                <div className="col-lg-6 col-sm-12">
                  <div
                    className="load_item wow fadeInUp load-item-min-height"
                    data-wow-delay="0.1s"
                    style={{ width: "100%", height: 350 }}
                  >
                    <DashTitle>Dashboard Overview</DashTitle>
                    <StatsWrapper>
                      <StatItem>
                        <div className="flex_container">
                          <div className="flex_stat">Gross</div>
                          <div className="flex_stat right-align">
                            $224,163.28
                          </div>
                        </div>
                      </StatItem>
                      <StatItem>
                        <div className="flex_container">
                          <div className="flex_stat">Current</div>
                          <div className="flex_stat right-align">
                            ${displayCurrentWeek()}
                          </div>
                        </div>
                      </StatItem>
                      <StatItem>
                        <div className="flex_container">
                          <div className="flex_stat">Weekly Avg</div>
                          <div className="flex_stat right-align">
                            ${calcWeeklyAvg()}
                          </div>
                        </div>
                      </StatItem>
                      <StatItem>
                        <div className="flex_container">
                          <div className="flex_stat">Avg RPM</div>
                          <div className="flex_stat right-align">
                            ${calcAvgRPM()}
                          </div>
                        </div>
                      </StatItem>
                      <StatItem>
                        <div className="flex_container">
                          <div className="flex_stat">Monthly Avg</div>
                          <div className="flex_stat right-align">
                            $13,387.50
                          </div>
                        </div>
                      </StatItem>
                      <StatItem>
                        <div className="flex_container">
                          <div className="flex_stat">Avg Load</div>
                          <div className="flex_stat right-align">
                            ${calcAvgLoad()}
                          </div>
                        </div>
                      </StatItem>
                    </StatsWrapper>
                  </div>

                  <div
                    className="load_item wow fadeInUp load-item-min-height"
                    data-wow-delay="0.1s"
                    style={{ width: "100%", height: 350 }}
                  >
                    <DashTitle>Revenue</DashTitle>
                    <ResponsiveContainer>
                      <BarChart
                        width={500}
                        height={300}
                        data={weeklyData}
                        margin={{
                          top: 20,
                          right: 30,
                          left: 0,
                          bottom: 25,
                        }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="date" />
                        <YAxis />
                        <Tooltip />
                        <Legend
                          margin={{ top: 50, left: 0, right: 0, bottom: 0 }}
                        />
                        <Bar dataKey="weekly" fill="hsl(210,100%,56%)" />
                        <Bar dataKey="monthly" fill="hsl(210,100%,36%)" />
                      </BarChart>
                    </ResponsiveContainer>
                  </div>
                </div>

                <div className="col-lg-6 col-sm-12">
                  <div
                    className="load_item wow fadeInUp load-item-min-height"
                    data-wow-delay="0.1s"
                    style={{ width: "100%", height: 350 }}
                  >
                    <DashTitle>Rate Per Mile</DashTitle>
                    <ResponsiveContainer>
                      <AreaChart
                        width={450}
                        height={300}
                        data={graphData.reverse()}
                        syncId="anyId"
                        margin={{ top: 40, right: 20, bottom: 50, left: 0 }}
                      >
                        <defs>
                          <linearGradient
                            id="rpmareacolor"
                            x1="0"
                            y1="0"
                            x2="0"
                            y2="1"
                          >
                            <stop
                              offset="5%"
                              stopColor="hsl(120,61%,50%)"
                              stopOpacity={0.8}
                            />
                            <stop
                              offset="95%"
                              stopColor="hsl(120,61%,50%)"
                              stopOpacity={0}
                            />
                          </linearGradient>
                        </defs>
                        <Area
                          type="monotone"
                          dataKey="rpm"
                          // activeDot={{
                          //   stroke: "hsl(120,61%,50%)",
                          //   strokeWidth: 3,
                          //   r: 5,
                          // }}
                          stroke="hsl(120,61%,5%)"
                          fill="url(#rpmareacolor)"
                        />
                        {/* <Line type="monotone" dataKey="rpm" stroke="blue" /> */}
                        <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                        <XAxis dataKey="name" />
                        <YAxis dateKey="rpm" />
                        <Tooltip />
                      </AreaChart>
                    </ResponsiveContainer>
                  </div>
                  <div
                    className="load_item wow fadeInUp load-item-min-height"
                    data-wow-delay="0.1s"
                    style={{ width: "100%", height: 350 }}
                  >
                    <DashTitle>Rate Per Load</DashTitle>
                    <ResponsiveContainer>
                      <AreaChart
                        width={450}
                        height={300}
                        syncId="anyId"
                        data={graphData}
                        margin={{ top: 40, right: 20, bottom: 50, left: 0 }}
                      >
                        <defs>
                          <linearGradient
                            id="rateareacolor"
                            x1="0"
                            y1="0"
                            x2="0"
                            y2="1"
                          >
                            <stop
                              offset="5%"
                              stopColor="hsl(120,61%,25%)"
                              stopOpacity={0.8}
                            />
                            <stop
                              offset="95%"
                              stopColor="hsl(120,61%,25%)"
                              stopOpacity={0}
                            />
                          </linearGradient>
                        </defs>
                        <Area
                          type="monotone"
                          dataKey="rate"
                          stroke="hsl(120,61%,5%)"
                          fill="url(#rateareacolor)"
                        />
                        {/* <Line
                        type="monotone"
                        dataKey="rate"
                        stroke="lightgreen"
                      /> */}
                        <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                        <XAxis dataKey="name" />
                        <YAxis dateKey="rate" />
                        <Tooltip />
                      </AreaChart>
                    </ResponsiveContainer>
                  </div>
                </div>
              </div>
            </Fade>

            <Reveal effect="fadeInUp" duration={1500}>
              <Reveal effect="fadeInUp">
                <h2 className="mb_60 title_h2 t_color">Load Viewer</h2>
              </Reveal>
              <div className="flex_container fifty-percent">
                <div className="flex_search_inputs">
                  Order By
                  <input
                    name="keydir"
                    list="orders"
                    placeholder="key: direction"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    type="text"
                    className="form-control"
                    id="orderBy"
                    required
                  />
                </div>

                <div className="flex_search_inputs">
                  Where
                  <input
                    name="where"
                    value={where}
                    onChange={(e) => setWhere(e.target.value)}
                    type="text"
                    className="form-control"
                    required
                  />
                </div>
                <div className="flex_search_inputs">
                  Limit
                  <input
                    name="limit"
                    value={limit}
                    onChange={(e) => setLimit(e.target.value)}
                    type="text"
                    className="form-control"
                    required
                  />
                </div>
              </div>
              <CreateButton onClick={(e) => runSearch(e)} className="theme_btn">
                Search
              </CreateButton>
            </Reveal>

            <Reveal effect="fadeInLeft" duration={2000}>
              <span className="bottom_line" id="loads"></span>
            </Reveal>
            <Reveal effect="fadeInUp" duration={2000}>
              <CreateButton onClick={handleClick} className="theme_btn">
                Create a Load
              </CreateButton>
            </Reveal>
            <Reveal>
              {props.loading && (
                <p>
                  <img
                    alt=""
                    className="loadingSpinner"
                    src={require("./image/circles.svg")}
                  />
                </p>
              )}
            </Reveal>
          </div>
          <Fade bottom cascade duration={1000}>
            <div className="row">
              {props.articles.length > 0 &&
                props.articles.map((article, key) => (
                  <div className="col-lg-4 col-sm-6" key={key}>
                    <div
                      className="load_item wow fadeInUp load-item-min-height"
                      data-wow-delay="0.1s"
                    >
                      <div className="flex_container">
                        <div className="flex_load_item">
                          {article.loadStatus === "Active" && (
                            <span className="load-status">Active</span>
                          )}
                          {article.loadStatus === "Delivered" && (
                            <span className="load-status delivered">
                              Delivered
                            </span>
                          )}
                          {article.loadStatus === "Factored" && (
                            <span className="load-status factored">
                              Factored
                            </span>
                          )}
                        </div>

                        <FirstPicksWrapper>
                          {article.loadType}
                        </FirstPicksWrapper>
                        <PicksWrapper>
                          P: {article.pickNumber ? article.pickNumber : "1"}
                        </PicksWrapper>
                        <PicksWrapper>
                          D: {article.dropNumber ? article.dropNumber : "1"}
                        </PicksWrapper>
                      </div>

                      <div className="flex_container top_border zero-margin">
                        <div className="flex_load_info">
                          Origin
                          <a href=".#">
                            <h2 className="t_color">{article.pick}</h2>
                          </a>
                          <p className="dates">
                            {formatDate(article.pickDate)}
                          </p>
                          <p className="times">
                            {article.pickTimeStart} - {article.pickTimeEnd}
                          </p>
                        </div>

                        <div className="flex_load_info">
                          Destination
                          <a href=".#">
                            <h2 className="t_color">{article.drop}</h2>
                          </a>
                          <p className="dates">
                            {formatDate(article.dropDate)}
                          </p>
                          <p className="times">
                            {article.dropTimeStart} - {article.dropTimeEnd}
                          </p>
                        </div>
                      </div>
                      <div className="flex_container top_border">
                        <div className="flex_load_item green-money">
                          <p>&#36;{article.rate}</p>
                        </div>
                        <div className="flex_load_item">
                          <p>{article.miles} miles</p>
                        </div>
                        <div className="flex_load_item">
                          <p>
                            ${displayRPM(article.rate, article.miles)} /mile
                          </p>
                        </div>

                        <div className="flex_load_item">
                          <p>{article.weight} lbs.</p>
                        </div>
                        <div className="flex_load_item">
                          <p>{article.length}</p>
                        </div>
                        <div className="flex_load_item">
                          <p>{article.commodity}</p>
                        </div>
                        {article.notes && (
                          <div className="flex_load_item notes">
                            {article.notes}
                          </div>
                        )}
                      </div>
                      <div className="flex_container top_border t_color">
                        <div className="flex_load_full">
                          <h2>{article.brokerage}</h2>
                        </div>
                        <div className="flex_load_item">
                          <p>{article.pocName}</p>
                        </div>
                        <div className="flex_load_item">
                          <p>{article.pocPhone}</p>
                        </div>
                      </div>
                      <div className="flex_container top_border t_color">
                        <div className="flex_load_item center-text">
                          {article.rateConURL && (
                            <div>
                              <a href={article.rateConURL}>Rate Con</a>

                              <Reveal>
                                <iframe
                                  title="ratecon"
                                  src={article.rateConURL}
                                />
                              </Reveal>
                            </div>
                          )}
                        </div>
                        <div className="flex_load_item center-text">
                          {article.podURL && (
                            <div>
                              <a href={article.podURL}>POD</a>

                              <Reveal>
                                <iframe title="pod" src={article.podURL} />
                              </Reveal>
                              {/* )} */}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="flex_container top_border">
                        <div className="flex_load_item">
                          {article.actor.date.toDate().toLocaleDateString()}
                        </div>
                        <div className="flex_load_item right-align">
                          {/* {article.loadType && <span>{article.loadType}</span>} */}
                          <ActionButton
                            className="theme_btn"
                            onClick={(e) => handleEdit(e, article.id, key)}
                            // className="theme_btn"
                          >
                            Edit
                          </ActionButton>
                          <ActionButton
                            className="theme_btn"
                            onClick={(e) => handleDelete(e, article.id, key)}
                            // className="theme_btn"
                          >
                            Delete
                          </ActionButton>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </Fade>
        </div>
      </section>

      <Footer jhonData={jhonData} />
    </div>
  );
};

const CreateButton = styled.div`
  font-size: 16px;
  font-weight: 700;
  padding: 4px;
  margin: auto;
  margin-top: 10px;
`;

const ActionButton = styled.button`
  font-size: 12px;
  font-weight: 700;
  margin: 0;
  padding: 0;
  height: 30px;
  width: 60px;
  min-width: auto;
  margin-left: 4px;
`;

const FirstPicksWrapper = styled.div`
  flex: 1 0 25%;
  padding: 2px;
  font-size: 14px;
  font-weight: 500;
  /* font-size: 8px; */
  input {
    font-size: 11px;
  }
`;

const PicksWrapper = styled.div`
  flex: 1 0 10%;
  padding: 2px;
  font-size: 14px;
  font-weight: 500;
  /* font-size: 8px; */
  input {
    font-size: 11px;
  }
`;

const StatsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 40px;
`;

const StatItem = styled.div`
  flex: 1 0 40%;
  padding: 10px;
  margin-top: 10px;
  margin-right: 10px;
  border-top: 1px solid hsl(210, 100%, 36%);

  background-image: linear-gradient(
    to bottom,
    rgba(211, 211, 211, 0.5),
    rgba(211, 211, 211, 0)
  );

  @media (max-width: 768px) {
    font-size: 12px;
    font-weight: 700;
  }
`;

const DashTitle = styled.a`
  font-weight: 700;
`;

const mapStateToProps = (state) => {
  return {
    user: state.userState.user,
    loading: state.articleState.loading,
    articles: state.articleState.articles,
  };
};

const mapDispatchToProps = (dispatch) => ({
  // getArticles: () => dispatch(getArticlesAPI()),
  getArticlesBy: (search, where, limit) =>
    dispatch(getArticlesByAPI(search, where, limit)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoadManager);
