import React from "react";
import Navbar from "./component/Navbar/Navbar";
import Banner from "./component/Banner/Banner";
import About from "./component/About";
import Service from "./component/Service";
//import Portfolio from "./component/Portfolio/Portfolio";
// import Testimonial from "./component/Testimonial/Testimonial";
import Clientslogo from "./component/Clientslogo";
//import Blog from "./component/Blog/Blog";
// import MapContainer from "./component/Map";
import Contact from "./component/Contact";
import Skill from "./component/Skill/Skill";
import Footer from "./component/Footer";
import jhonData from "./component/jhonData";
// import Login from "./component/Login";

export const Home = () => (
  <div className="body_wrapper">
    <Navbar
      mContainer="custome_container"
      mainlogo="tomcat-transport-logo.png"
      stickylogo="tomcat-transport-logo-sticky.png"
    />
    <Banner jhonData={jhonData} />
    {/* <Login /> */}
    <About aClass="about_area" jhonData={jhonData} />
    <Clientslogo />

    <Service wClass="work_area" jhonData={jhonData} />
    <Skill />

    {/* <Testimonial />
    <MapContainer /> */}
    <Contact jhonData={jhonData} />
    <Footer jhonData={jhonData} />
  </div>
);
