import React, { Component } from "react";
import { Parallax } from "react-parallax/";
import { Fade, Reveal } from "react-reveal/";
import Login from "../../component/Login";
import styled from "styled-components";

const image = require("../../image/profile.jpg");

class Banner extends Component {
  render() {
    let jhonData = this.props.jhonData;
    return (
      <section id="home">
        <Parallax bgImage={image} strength={500} className="banner_area">
          <div className="container">
            <div className="banner_content">
              <Reveal effect="fadeInUp">
                <h5>WELCOME TO</h5>
              </Reveal>
              <Reveal effect="fadeInUp" duration={1500}>
                <h2>{jhonData.name}</h2>
              </Reveal>

              <h4 className="wow fadeInUp animated">
                Trusted Logistics Partners
              </h4>
              <Fade bottom cascade duration={1000}>
                <ul className="list_style social_icon">
                  {jhonData.socialLinks &&
                    jhonData.socialLinks.map((item) => {
                      return (
                        <li key={item.name}>
                          <a href={item.url}>
                            <i className={item.className}></i>
                          </a>
                        </li>
                      );
                    })}
                </ul>
                <div className="container">
                  <Wrapper>
                    <Login />
                  </Wrapper>
                </div>
              </Fade>
            </div>
          </div>
        </Parallax>
      </section>
    );
  }
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

export default Banner;
