import styled from "styled-components";
import React from "react";
import { connect } from "react-redux";
import { deleteLoadAPI } from "../actions";

const DeleteLoadModal = (props) => {
  const currentLoad = props.articles[props.loadKEY];

  const reset = (e) => {
    props.handleClick(e);
  };

  const deleteLoad = (e, lookupID) => {
    e.preventDefault();

    if (e.target !== e.currentTarget) {
      return;
    }

    props.deleteLoad(lookupID);
    reset(e);
  };

  const formatDate = (date) => {
    let newDate = date.split("-");
    newDate.push(newDate.shift());
    newDate = newDate.join("/");

    return newDate;
  };

  const displayRPM = (rate, miles) => {
    // formats rate per miles for display output
    // limits to 2 decimal places
    let rpmOutput = (parseInt(rate) / parseInt(miles)).toFixed(2);
    return rpmOutput;
  };

  return (
    <React.Fragment>
      {props.showDelete === "open" && (
        <Container>
          <Content>
            <Header>
              <h2 className="mb_0 title_h2 t_color">Delete Load</h2>
              <button className="theme_btn" onClick={(event) => reset(event)}>
                X
              </button>
            </Header>
            <MainContent>
              <Message>Are you sure you want to delete this Load?</Message>

              <div className="load_item wow fadeInUp" data-wow-delay="0.1s">
                <div className="flex_container">
                  <div className="flex_load_item">
                    {currentLoad.loadStatus === "Active" && (
                      <span className="load-status">Active</span>
                    )}
                    {currentLoad.loadStatus === "Delivered" && (
                      <span className="load-status delivered">Delivered</span>
                    )}
                    {currentLoad.loadStatus === "Factored" && (
                      <span className="load-status factored">Factored</span>
                    )}
                  </div>

                  <FirstPicksWrapper>{currentLoad.loadType}</FirstPicksWrapper>
                  <PicksWrapper>
                    P: {currentLoad.pickNumber ? currentLoad.pickNumber : "1"}
                  </PicksWrapper>
                  <PicksWrapper>
                    D: {currentLoad.dropNumber ? currentLoad.dropNumber : "1"}
                  </PicksWrapper>
                </div>
                <div className="flex_container top_border">
                  <div className="flex_load_info">
                    Origin
                    <a href=".#">
                      <h2 className="t_color">{currentLoad.pick}</h2>
                    </a>
                    <p className="dates">{formatDate(currentLoad.pickDate)}</p>
                    <p className="times">
                      {currentLoad.pickTimeStart} - {currentLoad.pickTimeEnd}
                    </p>
                  </div>
                  <div className="flex_load_info">
                    Destination
                    <a href=".#">
                      <h2 className="t_color">{currentLoad.drop}</h2>
                    </a>
                    <p className="dates">{formatDate(currentLoad.dropDate)}</p>
                    <p className="times">
                      {currentLoad.dropTimeStart} - {currentLoad.dropTimeEnd}
                    </p>
                  </div>
                </div>
                <div className="flex_container top_border">
                  <div className="flex_load_item green-money">
                    <p>&#36;{currentLoad.rate}</p>
                  </div>
                  <div className="flex_load_item">
                    <p>{currentLoad.miles} miles</p>
                  </div>
                  <div className="flex_load_item">
                    <p>
                      ${displayRPM(currentLoad.rate, currentLoad.miles)} /mile
                    </p>
                  </div>
                  {/* </div> */}
                  {/* <div className="flex_container"> */}
                  <div className="flex_load_item">
                    <p>{currentLoad.weight} lbs.</p>
                  </div>
                  <div className="flex_load_item">
                    <p>{currentLoad.length}</p>
                  </div>
                  <div className="flex_load_item">
                    <p>{currentLoad.commodity}</p>
                  </div>
                  {currentLoad.notes && (
                    <div className="flex_load_item notes">
                      {currentLoad.notes}
                    </div>
                  )}
                </div>

                <div className="flex_container top_border t_color">
                  <div className="flex_load_full">
                    <h2>{currentLoad.brokerage}</h2>
                  </div>
                  <div className="flex_load_item">
                    <p>{currentLoad.pocName}</p>
                  </div>
                  <div className="flex_load_item">
                    <p>{currentLoad.pocPhone}</p>
                  </div>
                </div>
                <div className="flex_container top_border t_color">
                  <div className="flex_load_item center-text">
                    {currentLoad.rateConURL && (
                      <iframe title="ratecon" src={currentLoad.rateConURL} />
                    )}
                    Rate Con
                  </div>
                  <div className="flex_load_item center-text">
                    {currentLoad.podURL && (
                      <iframe title="pod" src={currentLoad.podURL} />
                    )}
                    POD
                  </div>
                </div>
              </div>
            </MainContent>
            <ShareCreation>
              <DeleteButton
                className="theme_btn"
                onClick={(event) => deleteLoad(event, props.loadID)}
              >
                Delete Load
              </DeleteButton>
            </ShareCreation>
          </Content>
        </Container>
      )}
    </React.Fragment>
  );
};

const DeleteButton = styled.div`
  font-size: 14px;
  font-weight: 700;
  padding: 2px;
  margin: auto;
`;

const Message = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 30px;
  padding-bottom: 30px;
  font-weight: 800;
`;

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  color: black;
  background-color: rgba(0, 0, 0, 0.8);
  animation: fadeIn 0.4s;
`;

const Content = styled.div`
  width: 100%;
  max-width: 552px;
  background-color: white;
  max-height: 90%;
  overflow: initial;
  border-radius: 5px;
  position: relative;
  display: flex;
  flex-direction: column;
  top: 32px;
  margin: 0 auto;
`;

const Header = styled.div`
  padding: 16px 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  font-size: 16px;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.6);
  font-weight: 400;
  display: flex;
  justify-content: space-between;
  align-items: center;
  h2 {
    margin-bottom: 0;
  }
  button {
    display: flex;
    flex-direction: column;
    height: 40px;
    width: 40px;
    min-width: auto;
    align-items: center;
    margin: 0;
    padding: 5px;
  }
`;

const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: auto;
  vertical-align: baseline;
  background: transparent;
  padding: 8px 12px;
`;

const ShareCreation = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 12px 24px 12px 16px;
`;

const FirstPicksWrapper = styled.div`
  flex: 1 0 25%;
  padding: 2px;
  font-size: 14px;
  font-weight: 800;
  /* font-size: 8px; */
  input {
    font-size: 11px;
  }
`;

const PicksWrapper = styled.div`
  flex: 1 0 10%;
  padding: 2px;
  font-size: 14px;
  font-weight: 800;
  /* font-size: 8px; */
  input {
    font-size: 11px;
  }
`;

const mapStateToProps = (state) => {
  return {
    user: state.userState.user,
    articles: state.articleState.articles,
  };
};

const mapDispatchToProps = (dispatch) => ({
  deleteLoad: (lookupID) => dispatch(deleteLoadAPI(lookupID)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DeleteLoadModal);
