import styled from "styled-components";
import React from "react";
import { connect } from "react-redux";
import { signInAPI } from "../actions";
import { Redirect } from "react-router";

// NEED TO UNCOMMENT THE REDIRECT FOR FINAL VERSION

const Login = (props) => {
  return (
    // <section className="about_area" id="Login">
    <div className="centerContent">
      {props.user && <Redirect to="/LoadManager" />}

      <Google onClick={() => props.signIn()}>
        <img src={require("../image/google.svg")} alt="" />
        Sign in with Google
      </Google>
    </div>
    // </section>
  );
};

const Google = styled.button`
  display: flex;
  justify-content: center;
  background-color: rgba(207, 207, 207, 0.65);
  align-items: center;
  height: 45px;
  width: 200px;
  border-radius: 28px;
  box-shadow: inset 0 0 0 1px rgb(0 0 0 / 60%),
    inset 0 0 0 2px rgb(0 0 0 / 0%) inset 0 0 0 1px rgb(0 0 0 / 0%);
  vertical-align: middle;
  z-index: 0;
  transition-duration: 167ms;
  font-size: 15px;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.6);
  &:hover {
    background-color: rgba(207, 207, 207, 0.25);
    color: rgba(0, 0, 0, 0.75);
  }
`;

const mapStateToProps = (state) => {
  return {
    user: state.userState.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    signIn: () => dispatch(signInAPI()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
