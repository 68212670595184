import firebase from "firebase";

const firebaseConfig = {
  apiKey: "AIzaSyA_KmPS4l5RzfmxZ9FDaskVkaNt9uUs06E",
  authDomain: "tomcat-transport-llc.firebaseapp.com",
  projectId: "tomcat-transport-llc",
  storageBucket: "tomcat-transport-llc.appspot.com",
  messagingSenderId: "947389155212",
  appId: "1:947389155212:web:d23ba8b054daf30f9038a9",
  measurementId: "G-KYYH40NM06",
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
const db = firebaseApp.firestore();
const auth = firebase.auth();
const provider = new firebase.auth.GoogleAuthProvider();
const storage = firebase.storage();

export { auth, provider, storage };
export default db;
