import React from "react";
import Sticky from "react-stickynode";
import { Link } from "react-scroll";
import { connect } from "react-redux";
import { signOutAPI } from "../../actions";
// import Login from "../../component/Login";
import styled from "styled-components";
import { Redirect } from "react-router-dom";

const LoadNav = (props) => {
  var { mClass, mContainer, mainlogo, stickylogo } = props;
  let validUser = false;

  if (props.user) {
    if (
      props.user.displayName === "Matthew Bussen" ||
      props.user.displayName === "Pete Bussen"
    ) {
      validUser = true;
    }
  }

  return (
    <Sticky top={0} innerZ={9999} activeClass="navbar_fixed">
      {/* {!props.user && <Redirect to="/" />} */}
      {validUser !== true && <Redirect to="/" />}
      <nav className={`navbar navbar-expand-lg navbar-light ${mClass}`}>
        <div className={`container ${mContainer}`}>
          <a className="navbar-brand logo_h" href="./">
            <img
              src={require("../../image/" + mainlogo)}
              alt="Tomcat Transport Logo"
            />
            <img
              src={require("../../image/" + stickylogo)}
              alt="Tomcat Transport Logo"
            />
          </a>
          {/* <a
            href="./"
            onClick={() => props.signOut()}
            className="btn get-btn get-btn-two d-lg-none d-md-block login"
          >
            Logout
          </a> */}
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
          </button>
          <div
            className="collapse navbar-collapse offset"
            id="navbarSupportedContent"
          >
            <ul className="nav navbar-nav m-auto">
              <li className="nav-item">
                <Link
                  className="nav-link"
                  activeClass="active"
                  to="dashboard"
                  spy={true}
                  smooth={true}
                  offset={-86}
                  duration={500}
                >
                  Dashboard
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  activeClass="active"
                  to="loads"
                  spy={true}
                  smooth={true}
                  offset={-86}
                  duration={500}
                >
                  Loads
                </Link>
              </li>
              <li className="nav-item">
                <LogoutWrapper>
                  <Link
                    className="nav-link"
                    activeClass="active"
                    to="./"
                    spy={true}
                    smooth={true}
                    offset={-86}
                    duration={500}
                    onClick={() => props.signOut()}
                  >
                    Logout
                  </Link>
                </LogoutWrapper>
              </li>

              {/* <li className="nav-item">
                <UserInfo>
                  {props.user && props.user.photoURL && (
                    <React.Fragment>
                      <img src={props.user.photoURL} alt="Profile" />
                      <span>{props.user.displayName}</span>
                    </React.Fragment>
                  )}
                </UserInfo>
              </li> */}
            </ul>
            <ul className="nav navbar-nav navbar-right d-md-none d-lg-block">
              <li className="nav-item">
                <a href="./" onClick={() => props.signOut()} className="login">
                  Logout
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </Sticky>
  );
};

const LogoutWrapper = styled.div`
  @media (min-width: 768px) {
    display: none;
  }
`;

const UserInfo = styled.div`
  display: flex;
  align-items: center;
  padding: 0;
  svg,
  img {
    width: 48px;
    height: 48px;
    background-clip: content-box;
    border: 2px solid transparent;
    border-radius: 50%;
    margin-left: 4px;
  }
  span {
    font-weight: 600;
    font-size: 16px;
    line-height: 1.5;
    margin-left: 5px;
  }
`;

const mapStateToProps = (state) => {
  return {
    user: state.userState.user,
  };
};

const mapDispatchToProps = (dispatch) => ({
  signOut: () => dispatch(signOutAPI()),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoadNav);
